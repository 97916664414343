<template>
  <section class="content">
    <div class="page-content page-content-noheader">
      <div class="cat-overview">
        <h1>Countries</h1>
        <div
          v-if="cleanCountries && cleanCountries.length"
          class="cat-overview-links"
        >
          <router-link
            v-for="item in cleanCountries"
            :key="item.id"
            :to="{ path: item.code }"
          >
            {{ item.name }}
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { mapState } from 'vuex';

export default {
    name: 'CountriesOverview',
    // props: ['countries'],
    computed: {
        ...mapState(['countries']),
        cleanCountries() {
            // Assuming 'countries' is already an array. No need to check for null/undefined.
            let filtered = this.countries.filter(item => item.code !== 'DEWO' && item.code !== 'MM');
            return filtered.sort((a, b) => a.name.localeCompare(b.name));
        }
    },
    created() {
        this.$parent.showHeader = false,
            this.$parent.showDownloadButton = false
    },
    mounted() {
        // Dispatch the action to set countries if the store is empty
        if (this.countries.length < 1) {
            this.$store.dispatch('setCountries');
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>