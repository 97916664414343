<template>
  <section class="content">
    <div class="page-content page-content-noheader">
      <div class="cat-overview">
        <h1>Please enter password</h1>

        <div class="container text-center">
          <div class="row">
            <div class="col-md-6 offset-md-3">
              <form @submit.prevent="validateBeforeSubmit">
                <div class="form-group text-left">
                  <input
                    v-model.trim="password"
                    class="form-control password-field"
                    type="password"
                    name="password"
                  >
                  <span class="error help-block" />
                </div>
                <div
                  v-if="error"
                  class="text-danger"
                >
                  <p>Incorrect password.</p>
                </div>
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import storageHelper from 'storage-helper'

export default {
  name: 'ProtectedView',
  props: {
    msg: String
  },
  data() {
    return {
      error: null,
      password: null
    }
  },
  methods: {
    validateBeforeSubmit() {
      if (this.password === 'DC2020-2021!') {
        this.error = false
        storageHelper.setItem('user-password', this.password)
        this.$router.push(this.$route.query.redirect)
      } else {
        this.error = true
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page-content {
  text-align: center;
}

label {
  display: block;
  font-size: 14px;
  font-weight: bold;
}

input[type="password"] {
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  margin-top: 0.4em;
  margin-bottom: 1em;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

button[type="submit"] {
  background: black;
  color: #fff;
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.text-danger p {
  display: inline-block;
  background: pink;
  padding: 10px 20px;
  color: darkred;
  border: solid 1px lightpink;
}
</style>













