import { toRaw } from 'vue'
import { createStore } from 'vuex'
import { getCatalogs, getCategoriesInCountry, getCountries } from '../apiFunctions'

// Create a new store instance for storing state persistent data
export const store = createStore({
    state() {
        return {
            // apiUrl: "https://v4.onlinedatabasetool.com/api",
            apiUrl: "https://v4.onlinedatabasetool.com/api",
            downloadUrl: "https://v4.onlinedatabasetool.com",
            countries: [],
            countryCode: null,
            countryId: null,
            catalogs: [],
            isLoading: false,
            categoryId: null,
            categories: [],
            labels: [],
        }
    },
    getters: {
        isValidCountryCode: (state) => (countryCode) => {
            return toRaw(state.countries).find(country => country.code === countryCode) !== undefined
        },
        getCountryCodeFromId: (state) => (countryId) => {
            return toRaw(state.countries).find(country => country.id === countryId).code
        },
        getCountries: (state) => {
            return state.countries
        },
        getCountryCode: (state) => {
            return state.countryCode
        },
        getCountryId: (state) => {
            return state.countryId
        },
        getCatalogs: (state) => {
            return state.catalogs
        },
        getCategories: (state) => {
            return state.categories
        }
    },
    mutations: {
        setCountries(state, payload) {
            state.countries = payload.countries
        },
        setCountryId(state, payload) {
            state.countryId = payload.countryId
        },
        setCountryCode(state, payload) {
            state.countryCode = payload.countryCode
        },
        setCatalogs(state, payload) {
            state.catalogs = payload.catalogs
        },
        setCategories(state, payload) {
            state.categories = payload.categories
            state.labels = payload.labels
        },
        toggleIsLoading(state, payload) {
            state.isLoading = payload
        }
    },
    actions: {
        async setCountryId({ commit, state }, countryCode) {
            if (!state.countries.length) {
                try {
                    const countries = await getCountries();
                    commit('setCountries', { countries });
                } catch (error) {
                    console.error("Error fetching countries:", error);
                }
            }

            const country = state.countries.find(c => c.code === countryCode);
            if (country) {
                commit('setCountryId', { countryId: country.id });
            }
        },
        setCountryCode({ commit }, countryCode) {
            commit('setCountryCode', {
                countryCode: countryCode
            })
        },
        async setCountries({ commit }) {
            commit('toggleIsLoading', true)
            commit('setCountries', {
                countries: await getCountries()
            })
            commit('toggleIsLoading', false)
        },
        async setCatalogs({ commit }, params) {
            commit('toggleIsLoading', true);
            commit('setCatalogs', {
                catalogs: await getCatalogs(params.countryCode)
            })
            commit('toggleIsLoading', false);
        },


        async setCategories({ commit }, params) {
            commit('toggleIsLoading', true);
            const response = await getCategoriesInCountry(params.catalogType, params.countryCode);

            commit('setCategories', {
                categories: response.categories,
                labels: response.labels
            })
            commit('toggleIsLoading', false);
        }
    }
})