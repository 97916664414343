import axios from 'axios'
import { createApp } from 'vue'
import VueGtag from 'vue-gtag'
import Toast from "vue-toastification"
import App from './App'
import router from './router'
import { store } from './store/store.js'
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"

const app = createApp(App)
// Install the store instance as a plugin
app.use(store)
app.config.globalProperties.$showHeader = false
app.config.globalProperties.$showDownloadButton = false
//init Vue Router
app.use(router)


// only enable GA tracking code on production
var host = window.location.hostname;
if (host != "localhost") {
    app.use(VueGtag, {
        // eslint-disable-next-line
        config: { id: process.env.VUE_APP_ANALYTICS_TRACKING_ID }
    }, router)
}
app.use(Toast, {
    maxToasts: 1,
})
app.mount('#app')
app.use(axios)

