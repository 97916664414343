<template>
  <section class="content">
    <div class="page-content page-content-noheader">
      <div class="cat-overview">
        <h1>Catalogues</h1>

        <div v-if="!isLoading">
          <div v-for="catalog in catalogs" :key="catalog.id" class="cat-overview-links">
            <router-link :to="{ path: catalogUrlMapping[catalog.name].path }" append>
              {{ catalogUrlMapping[catalog.name].name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CatalogueView',
  computed: {
    ...mapState(['countryId', 'catalogs', 'isLoading']), // Mapping isLoading from Vuex state
    countryCode() {
      return this.$route.params.countryCode; // Reactive to route changes
    },
    catalogUrlMapping() {
      return {
        'Digital Premium': {
          path: '/' + this.countryCode + '/premium',
          name: 'Premium'
        },
        'Digital Partner': {
          path: '/' + this.countryCode + '/partner',
          name: 'Partner'
        },
        'Digital Urban Electro': {
          path: '/' + this.countryCode + '/' + 'urban electro',
          name: 'Urban Electro'
        }
      }
    },
  },
  created() {
    this.$parent.showHeader = true;
    this.$parent.showDownloadButton = false;
  },
  mounted() {
    this.$store.dispatch('setCatalogs', {
      countryCode: this.countryCode,
    });
  }
}
</script>




