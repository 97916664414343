<template>
    <section class="content" v-if="!isLoading">
        <div v-if="!isPreview" class="header-cat" :class="categoryClass">
            {{ categoryLabel }}
        </div>
        <ProductOverview v-if="!isSingleProductPage" :products="products" />
        <TemplateProduct v-if="isProductDataReady" :catalogline-api-data="cataloglineApiData" :country-code="countryCode"
            :is-preview="isPreview" :publication-name="catalogType" :category-class="categoryClass" />
    </section>
</template>

<script>
import ProductOverview from '@/components/product/Products.vue'
import { mapState } from 'vuex'
import { store } from '../../store/store'
// import Product from '@/components/product/Product.vue'
import TemplateProduct from '@/components/product/Product_stefan.vue'
import { getProductsInCategory, getSingleProduct } from '../../apiFunctions'

export default {
    name: 'ProductBaseTemplate',
    components: {
        ProductOverview,
        TemplateProduct,
    },
    data() {
        return {
            categoryClass: this.$route.params.categoryName,
            products: [],
            categoryLabel: '',
            importId: this.$route.params.importId || null,
            cataloglineApiData: null,
            isPreview: false
        }
    },
    computed: {
        ...mapState(['countryId', 'catalogs', 'countryCode']),
        isSingleProductPage() {
            return Object.keys(this.$route.params).includes('importId')
        },
        isProductDataReady() {
            return this.cataloglineApiData !== null && this.isSingleProductPage;
        },
        catalogType() {
            return this.$route.params.catalogType
        },
        categoryId() {
            return this.$route.params.categoryId
        },
    },
    watch: {
        '$route': {
            immediate: true, // This means the watcher will also trigger on component creation
            async handler() {
                if (this.isSingleProductPage) {
                    await this.fetchProduct();
                } else {
                    await this.fetchCategory();
                }
            }
        }
    },
    created() {
        this.$parent.showHeader = true,
            this.$parent.showDownloadButton = true
    },
    mounted() {
        this.$store.dispatch('setCatalogs', {
            countryCode: this.countryCode,
        });
    },
    methods: {
        async fetchProduct() {
            store.commit('toggleIsLoading', true);
            this.cataloglineApiData = await getSingleProduct(
                this.$route.params.countryCode,
                this.$route.params.catalogType,
                this.$route.params.importId
            );
            this.categoryLabel = this.cataloglineApiData.category.label;
            store.commit('toggleIsLoading', false);
        },
        async fetchCategory() {
            store.commit('toggleIsLoading', true);
            this.products = await getProductsInCategory(
                this.$route.params.countryCode,
                this.$route.params.categoryName,
                this.$route.params.catalogType
            );
            this.categoryLabel = this.products[0].category.label;
            store.commit('toggleIsLoading', false);
        }
    },


}
</script>

