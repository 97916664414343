
<template>
    <section class="content" v-if="!isLoading">
        <div class="header-cat" :class="categoryClass">
            {{ categoryLabel }}
        </div>

        <div class="page-content">
            <TemplateAccessory v-if="isSingleAccessoryPage" :catalogline-api-data="cataloglineApiData"
                :is-preview="isPreview" :country-code="countryCode" :publication-name="catalogType" />
            <AccessoriesOverview v-if="!isSingleAccessoryPage" :accessories="accessories" />
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import { getProductsInCategory, getSingleProduct } from '../../apiFunctions';
import { store } from '../../store/store';
import AccessoriesOverview from './Accessories.vue';
import TemplateAccessory from './Accessory.vue';

export default {
    name: 'AccessoryBaseTemplate',
    components: {
        TemplateAccessory,
        AccessoriesOverview
    },
    data() {
        return {
            // Other data properties...
            accessories: [], // Define accessories here
            categoryLabel: '', // Define categoryLabel here
            // ...
        };
    },
    computed: {
        ...mapState(['isLoading']),
        countryCode() {
            return this.$route.params.countryCode;
        },
        category() {
            return this.$route.params.category;
        },
        categoryClass() {
            return this.$route.params.categoryName;
        },
        catalogType() {
            return this.$route.params.catalogType;
        },
        importId() {
            return this.$route.params.importId;
        },
        isSingleAccessoryPage() {
            return !!this.importId;
        }
    },
    watch: {
        '$route': {
            immediate: true,
            async handler() {
                this.isSingleAccessoryPage ? await this.fetchProduct() : await this.fetchCategory();
            }
        }
    },
    created() {
        this.$parent.showHeader = true;
        this.$parent.showDownloadButton = true;
    },
    mounted() {
        this.$store.dispatch('setCatalogs', {
            countryCode: this.countryCode,
        });
    },
    methods: {
        async fetchProduct() {
            this.cataloglineApiData = await getSingleProduct(this.countryCode, this.catalogType, this.importId);
            this.categoryLabel = this.cataloglineApiData?.category?.label;
        },
        async fetchCategory() {
            store.commit('toggleIsLoading', true);
            this.accessories = await getProductsInCategory(this.countryCode, this.categoryClass, this.catalogType);
            if (this.accessories && this.accessories.length > 0) {
                this.categoryLabel = this.accessories[0].category?.label;
            }
            store.commit('toggleIsLoading', false);
        }
    }
}
</script>