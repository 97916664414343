import axios from 'axios'
import { store } from './store/store.js'


export function getCountriesFromAPI() {
    // console.log('running getCountries()')
    return axios.get(`${store.state.apiUrl}/countries`)
}

export async function getCountries() {
    const response = await getCountriesFromAPI()
    return response.data
}

export async function getCatalogs(countryCode) {
    const catalogs = await axios.get(`${store.state.apiUrl}/catalogs/${countryCode}`)
    return catalogs.data
}


export async function getCategoriesInCountry(catalogType, countryCode) {
    const response = await axios.get((`${store.state.apiUrl}/categoriesincountry/${countryCode}/${catalogType}`))
    return response.data
}

export async function getProductsInCategory(countryCode, categoryName, catalogType) {
    return axios
        .get(`${store.state.apiUrl}/cataloglines/${countryCode}/${categoryName}/${catalogType}`)
        .then(function (response) {
            return response.data
        })
        .catch(error => console.log(error))
}

export async function getSingleProduct(countryId, catalogType, importId) {
    return axios
        .get(`${store.state.apiUrl}/catalogline/${countryId}/${catalogType}/${importId}`)
        .then(function (response) {
            return response.data
        })
        .catch(error => console.log(error))
}