<template>
  <div class="page-content">
    <div v-if="!isPreview" class="related-btns">
      <router-link v-if="prevId"
        :to="{ path: '/' + countryCode + '/' + publicationName + '/barbecues/' + category.code + '/' + prevImportId }"
        class="prev">
        &lsaquo;
      </router-link>
      <router-link v-if="nextId"
        :to="{ path: '/' + countryCode + '/' + $route.params.catalogType + '/barbecues/' + category.code + '/' + nextImportId }"
        class="next">
        &rsaquo;
      </router-link>
    </div>

    <div class="product-wrapper">
      <div class="product-container">
        <div v-if="countryCode !== 'RU'">
          <img v-if="product.premium_icon && product.premium_icon?.value"
            src="https://onlinedatabasetool.com/template-assets/img/icon-premium.png" class="icon-premium">
          <img v-if="product.partner_icon && countryCode !== 'NO' && countryCode !== 'DK' && product.partner_icon.value"
            src="https://onlinedatabasetool.com/template-assets/img/icon-partner.png" class="icon-premium">
          <img
            v-if="product.pure_online_assortment_icon && product.pure_online_assortment_icon.value && countryCode !== 'NO' && countryCode !== 'DK'"
            src=" https://onlinedatabasetool.com/template-assets/img/PURE_ONLINE_ASSORTMENT_ICON.png" width="30"
            class="icon-premium">
          <img v-if="product.non_sds_icon && product.non_sds_icon.value && countryCode !== 'NO' && countryCode !== 'DK'"
            src="https://onlinedatabasetool.com/template-assets/img/NON_SDS_ICON.png" width="30" class="icon-premium">
          <img
            v-if="product.weber_own_channels_icon && product.weber_own_channels_icon.value && countryCode !== 'SE' && countryCode !== 'NO' && countryCode !== 'DK'"
            src="https://onlinedatabasetool.com/template-assets/img/WEBER_OWN_CHANNELS_ICON.png" class="icon-premium">
          <img
            v-if="product.urban_electro_icon && product.urban_electro_icon.value && countryCode !== 'SE' && countryCode !== 'NO' && countryCode !== 'DK'"
            src="https://onlinedatabasetool.com/template-assets/img/urban_electro_icon_round.png" width="30px"
            class="icon-premium">
          <img
            v-if="product.weber_world_icon && product.weber_world_icon.value && countryCode !== 'SE' && countryCode !== 'NO' && countryCode !== 'DK'"
            src="https://onlinedatabasetool.com/template-assets/img/WEBER_WORLD_ICON.png" width="30px"
            class="icon-premium">
        </div>

        <h1>{{ product.product_title_line_1.value }} {{ product.product_title_line_2.value }}</h1>
        <p>{{ product.product_description.value }}</p>

        <div class="product-img-wrapper">
          <img v-if="product.stock_icon.value" src="https://onlinedatabasetool.com/template-assets/img/icon-stock.png"
            class="product-image-icon icon-stock" id="icon-stock" width="52">
          <img v-if="product.guarantee_years.value"
            :src="'https://onlinedatabasetool.com/template-assets/img/translations/' + countryCode + '/' + product.guarantee_years.value + '.png'"
            class="guarantee">
          <img v-if="product.new_icon.value"
            :src="'https://onlinedatabasetool.com/template-assets/img/translations/' + countryCode + '/NEW_icon.png'"
            class="new">
          <span v-if="!isNordic"><img v-if="product.bic_icon.value"
              src="https://onlinedatabasetool.com/template-assets/img/icon-BIC.png" class="bic"></span>
          <span v-if="!isNordic"><img v-if="product.special_icon.value"
              src="https://onlinedatabasetool.com/template-assets/img/icon-special.png" class="special"></span>
          <span v-if="!isNordic"><img v-if="product.pulse_icon.value"
              src="https://onlinedatabasetool.com/template-assets/img/icon-pulse.png" class="special"></span>
          <img
            :src="'https://onlinedatabasetool.com/template-assets/img/barbecues/' + product.barbecue_code.value + '.jpg'"
            class="product-img" alt="">
        </div>

        <div class="features">
          <h2>{{ product.sales_arguments?.label }}</h2>
          <ul>
            <li v-if="product.sales_arguments.value">
              {{ product.sales_arguments.value }}
            </li>
            <li v-if="product.sales_arguments_2.value">
              {{ product.sales_arguments_2.value }}
            </li>
            <li v-if="product.sales_arguments_3.value">
              {{ product.sales_arguments_3.value }}
            </li>
            <li v-if="product.sales_arguments_4.value">
              {{ product.sales_arguments_4.value }}
            </li>
            <li v-if="product.sales_arguments_5.value">
              {{ product.sales_arguments_5.value }}
            </li>
            <li v-if="product.sales_arguments_6.value">
              {{ product.sales_arguments_6.value }}
            </li>
            <li v-if="product.sales_arguments_7.value">
              {{ product.sales_arguments_7.value }}
            </li>
            <li v-if="product.sales_arguments_8.value">
              {{ product.sales_arguments_8.value }}
            </li>
            <li v-if="product.sales_arguments_9.value">
              {{ product.sales_arguments_9.value }}
            </li>
            <li v-if="product.sales_arguments_10.value">
              {{ product.sales_arguments_10.value }}
            </li>
            <li v-if="product.sales_arguments_11.value">
              {{ product.sales_arguments_11.value }}
            </li>
            <li v-if="product.sales_arguments_12.value">
              {{ product.sales_arguments_12.value }}
            </li>
            <li v-if="product.sales_arguments_13.value">
              {{ product.sales_arguments_13.value }}
            </li>
            <li v-if="product.sales_arguments_14.value">
              {{ product.sales_arguments_14.value }}
            </li>
            <li v-if="product.sales_arguments_15.value">
              {{ product.sales_arguments_15.value }}
            </li>
          </ul>
        </div>

        <div class="product-info">
          <h2>{{ product.product_information.label }}</h2>
          <p>
            <img
              :src="'https://onlinedatabasetool.com/template-assets/img/iconen/' + product.barbecue_icon.value.toLowerCase() + '_icon.png'"
              width="14"><strong>{{ product.type_barbecue.value }}</strong> <br>
            <img
              :src="'https://onlinedatabasetool.com/template-assets/img/iconen/' + product.grate_shape.value.toLowerCase() + '_icon.png'"
              width="14"><strong>{{ product.product_grate_metric.label }} </strong>
            {{ product.product_grate_metric.value }} <br>
            <img
              :src="'https://onlinedatabasetool.com/template-assets/img/iconen/' + product.product_color_icon.value + '_icon.png'"
              width="14"><strong>{{ product.product_color_icon.label }}</strong> {{ product.product_color.value }}
          </p>
        </div>

        <div class="dimensions">
          <h2>{{ product.product_dimensions.label }} <span>{{ product.product_dimensions_regular.label }}</span></h2>
          <!-- product size with open lid, only for BBQs --->
          <span
            v-if="product.product_size_metric_open_lid.label && product.product_size_metric_open_lid.value && category.isBarbecue"><strong>{{ product.product_size_metric_open_lid.label }}</strong>
            {{ product.product_size_metric_open_lid.value }} <br></span>
          <span
            v-if="product.product_size_metric_closed_lid.label"><strong>{{ product.product_size_metric_closed_lid.label }}</strong>
            {{ product.product_size_metric_closed_lid.value }} <br></span>
          <span v-if="product.gross_weight_metric.label"><strong>{{ product.gross_weight_metric.label }}</strong>
            {{ product.gross_weight_metric.value }}<br></span>
          <span v-if="product.net_weight_metric.label"><strong>{{ product.net_weight_metric.label }}</strong>
            {{ product.net_weight_metric.value }} <br></span>
          <span v-if="product.packaging_size_metric.label"><strong>{{ product.packaging_size_metric.label }}</strong>
            {{ product.packaging_size_metric.value }} <span v-if="product.packaging_size_GS1_compliant"><img
                src="https://onlinedatabasetool.com/template-assets/img/iconen/GS1.png" width="12"
                style="vertical-align:middle;margin-left:3px;"></span><br></span>
        </div>

        <div class="article-number">
          <p v-if="product.article_number_1.label">
            <strong>{{ product.article_number_1.label }}</strong> {{ product.article_number_1.value }} <span
              v-if="product.symbols_after_article_number_1">{{ product.symbols_after_article_number_1.value }} </span>
          </p>
          <img
            :src="'https://onlinedatabasetool.com/template-assets/img/barcodes/' + product.barcode_number_1.value + '.png'"
            alt="">
        </div>

        <div class="meta">
          <p>
            <span v-if="product.amount_packaging.label"><strong>{{ product.amount_packaging.label }}</strong>
              {{ product.amount_packaging.value }} <br></span>
            <span v-if="product.amount_per_pallet.label"><strong>{{ product.amount_per_pallet.label }}</strong>
              {{ product.amount_per_pallet.value }} <br></span>
            <!-- Dealer number, only for Nordics --->
            <span v-if="product?.dealer_number?.label && product?.dealer_number?.value">
              <strong>{{ product?.dealer_number.label }}</strong>
              {{ product?.dealer_number.value }} <br></span>

            <span v-if="product?.customs_code?.label && product?.customs_code?.value">
              <strong>{{ product.customs_code.label }}</strong>
              {{ product.customs_code.value }} <br></span>

            <!-- Suggested retail price, only for Switzerland --->
            <span v-if="isSwiss"><span
                v-if="product.suggested_retail_price_1.label"><strong>{{ product.suggested_retail_price_1.label }}</strong>
                {{ product.suggested_retail_price_1.value }} {{ product.symbols_after_suggested_retail_price.value }}
                <br></span></span>
          </p>
        </div>

        <div class="legal">
          <p>
            <span
              v-if="product.product_legal_text_warranty.value">{{ product.product_legal_text_warranty.value }}<br></span>
            <span
              v-if="product.additional_product_legal_text_1.value">{{ product.additional_product_legal_text_1.value }}<br></span>
            <span
              v-if="product.additional_product_legal_text_2.value">{{ product.additional_product_legal_text_2.value }}<br></span>
            <span
              v-if="product.additional_product_legal_text_3.value">{{ product.additional_product_legal_text_3.value }}</span>
          </p>
        </div>


        <div
          v-if="product.ddc_accessory_visual_1.value || product.ddc_accessory_visual_2.value || product.ddc_accessory_visual_3.value"
          id="accessory-caroussel" class="accessoires">
          <h2>{{ product.ddc_accessories_title.label }}</h2>
          {{ product.ddc_accessories_description.label }}
          <Flickity ref="flickity" class="flickity" :options="flickityOptions">
            <div v-if="product.ddc_accessory_visual_1.value && product.ddc_accessory_visual_1.accessory_line_id"
              class="carousel-cell">
              <router-link
                :to="{ path: '/' + countryCode + '/' + publicationName + '/Accessory/' + product.ddc_accessory_visual_1.accessory_product_importId }"
                class="products-overview-single">
                <img
                  :src="'https://onlinedatabasetool.com/template-assets/img/accessoires/' + product.ddc_accessory_visual_1.value + '.jpg'">
                <div class="product-single-accessory-text">
                  <span class="accessory-article-number">
                    {{ product.ddc_accessory_visual_1.value }}</span><br>
                  {{ product.ddc_accessory_title_1.value }}
                </div>
              </router-link>
            </div>

            <div v-if="product.ddc_accessory_visual_2.value" class="carousel-cell">
              <a :href="(product.ddc_accessory_visual_2.accessory_line_id !== undefined ? '/' + countryCode + '/' + publicationName + '/Accessory/' + product.ddc_accessory_visual_2.accessory_product_importId : 'javascript:void(0)')"
                class="products-overview-single">
                <img
                  :src="'https://onlinedatabasetool.com/template-assets/img/accessoires/' + product.ddc_accessory_visual_2.value + '.jpg'">
                <div class="product-single-accessory-text">
                  <span
                    class="accessory-article-number">{{ product.ddc_accessory_visual_2.value }}</span><br>{{ product.ddc_accessory_title_2.value }}
                </div>
              </a>
            </div>

            <div v-if="product.ddc_accessory_visual_3.value" class="carousel-cell">
              <a :href="(product.ddc_accessory_visual_3.accessory_line_id !== undefined ? '/' + countryCode + '/' + publicationName + '/Accessory/' + product.ddc_accessory_visual_3.accessory_product_importId : 'javascript:void(0)')"
                class="products-overview-single">
                <img
                  :src="'https://onlinedatabasetool.com/template-assets/img/accessoires/' + product.ddc_accessory_visual_3.value + '.jpg'">
                <div class="product-single-accessory-text">
                  <span
                    class="accessory-article-number">{{ product.ddc_accessory_visual_3.value }}</span><br>{{ product.ddc_accessory_title_3.value }}
                </div>
              </a>
            </div>
            <div v-if="product.ddc_accessory_visual_4.value" class="carousel-cell">
              <a :href="(product.ddc_accessory_visual_4.accessory_line_id !== undefined ? '/' + countryCode + '/' + publicationName + '/Accessory/' + product.ddc_accessory_visual_4.accessory_product_importId : 'javascript:void(0)')"
                class="products-overview-single">
                <img
                  :src="'https://onlinedatabasetool.com/template-assets/img/accessoires/' + product.ddc_accessory_visual_4.value + '.jpg'">
                <div class="product-single-accessory-text">
                  <span
                    class="accessory-article-number">{{ product.ddc_accessory_visual_4.value }}</span><br>{{ product.ddc_accessory_title_4.value }}
                </div>
              </a>
            </div>

            <div v-if="product.ddc_accessory_visual_5.value" class="carousel-cell">
              <a :href="(product.ddc_accessory_visual_5.accessory_line_id !== undefined ? '/' + countryCode + '/' + publicationName + '/Accessory/' + product.ddc_accessory_visual_5.accessory_product_importId : 'javascript:void(0)')"
                class="products-overview-single">
                <img
                  :src="'https://onlinedatabasetool.com/template-assets/img/accessoires/' + product.ddc_accessory_visual_5.value + '.jpg'">
                <div class="product-single-accessory-text">
                  <span
                    class="accessory-article-number">{{ product.ddc_accessory_visual_5.value }}</span><br>{{ product.ddc_accessory_title_5.value }}
                </div>
              </a>
            </div>

            <div v-if="product.ddc_accessory_visual_6.value" class="carousel-cell">
              <a :href="(product.ddc_accessory_visual_6.accessory_line_id !== undefined ? '/' + countryCode + '/' + publicationName + '/Accessory/' + product.ddc_accessory_visual_6.accessory_product_importId : 'javascript:void(0)')"
                class="products-overview-single">
                <img
                  :src="'https://onlinedatabasetool.com/template-assets/img/accessoires/' + product.ddc_accessory_visual_6.value + '.jpg'">
                <div class="product-single-accessory-text">
                  <span
                    class="accessory-article-number">{{ product.ddc_accessory_visual_6.value }}</span><br>{{ product.ddc_accessory_title_6.value }}
                </div>
              </a>
            </div>

            <div v-if="product.ddc_accessory_visual_7.value" class="carousel-cell">
              <a :href="(product.ddc_accessory_visual_7.accessory_line_id !== undefined ? '/' + countryCode + '/' + publicationName + '/Accessory/' + product.ddc_accessory_visual_7.accessory_product_importId : 'javascript:void(0)')"
                class="products-overview-single">
                <img
                  :src="'https://onlinedatabasetool.com/template-assets/img/accessoires/' + product.ddc_accessory_visual_7.value + '.jpg'">
                <div class="product-single-accessory-text">
                  <span
                    class="accessory-article-number">{{ product.ddc_accessory_visual_7.value }}</span><br>{{ product.ddc_accessory_title_7.value }}
                </div>
              </a>
            </div>

            <div v-if="product.ddc_accessory_visual_8.value" class="carousel-cell">
              <a :href="(product.ddc_accessory_visual_8.accessory_line_id !== undefined ? '/' + countryCode + '/' + publicationName + '/Accessory/' + product.ddc_accessory_visual_8.accessory_product_importId : 'javascript:void(0)')"
                class="products-overview-single">
                <img
                  :src="'https://onlinedatabasetool.com/template-assets/img/accessoires/' + product.ddc_accessory_visual_8.value + '.jpg'">
                <div class="product-single-accessory-text">
                  <span
                    class="accessory-article-number">{{ product.ddc_accessory_visual_8.value }}</span><br>{{ product.ddc_accessory_title_8.value }}
                </div>
              </a>
            </div>

            <div v-if="product.ddc_accessory_visual_9.value" class="carousel-cell">
              <a :href="(product.ddc_accessory_visual_9.accessory_line_id !== undefined ? '/' + countryCode + '/' + publicationName + '/Accessory/' + product.ddc_accessory_visual_9.accessory_product_importId : 'javascript:void(0)')"
                class="products-overview-single">
                <img
                  :src="'https://onlinedatabasetool.com/template-assets/img/accessoires/' + product.ddc_accessory_visual_9.value + '.jpg'">
                <div class="product-single-accessory-text">
                  <span
                    class="accessory-article-number">{{ product.ddc_accessory_visual_9.value }}</span><br>{{ product.ddc_accessory_title_9.value }}
                </div>
              </a>
            </div>

            <div v-if="product.ddc_accessory_visual_10.value" class="carousel-cell">
              <a :href="(product.ddc_accessory_visual_10.accessory_line_id !== undefined ? '/' + countryCode + '/' + publicationName + '/Accessory/' + product.ddc_accessory_visual_10.accessory_product_importId : 'javascript:void(0)')"
                class="products-overview-single">
                <img
                  :src="'https://onlinedatabasetool.com/template-assets/img/accessoires/' + product.ddc_accessory_visual_10.value + '.jpg'">
                <div class="product-single-accessory-text">
                  <span
                    class="accessory-article-number">{{ product.ddc_accessory_visual_10.value }}</span><br>{{ product.ddc_accessory_title_10.value }}
                </div>
              </a>
            </div>
          </Flickity>
        </div>

        <div class="image-bank-url" v-if="product.test_url.label && product.test_url.value">
          <a class="image-bank-button" target="_blank" :href="product.test_url.value">{{ product.test_url.label }}</a>
        </div>

      </div>
    </div>
  </div>
</template>



<script>

import { store } from '@/store/store.js'
import Flickity from 'vue-flickity'
export default {
  name: 'TemplateProduct',
  components: { Flickity },
  props: {
    cataloglineApiData: {
      type: Object,
      required: true,
    },
    countryCode: {
      type: String,
      required: true
    },
    publicationName: {
      type: String,
      required: true
    },
    isPreview: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 2,
        prevNextButtons: true,
        autoPlay: 3000,
        pauseAutoPlayOnHover: true,
        pageDots: true,
        wrapAround: true,
        freeScroll: true,
        lazyload: false,
        // any options from Flickity can be used
      }
    }
  },
  computed: {
    // Wat handige shortcuts om leesbaarheid template te vergroten
    product() {
      console.log(this.cataloglineApiData)
      return this.cataloglineApiData.product
    },
    category() {
      return this.cataloglineApiData.category
    },
    catalogType() {
      return this.$route.params.catalogType
    },
    lineId() {
      return this.cataloglineApiData.id
    },
    prevId() {
      return this.cataloglineApiData?.prevId
    },
    prevImportId() {
      return this.cataloglineApiData?.prevImportId
    },
    nextImportId() {
      return this.cataloglineApiData?.nextImportId
    },
    nextId() {
      return this.cataloglineApiData?.nextId
    },
    isNordic() {
      return ['NO', 'SE', 'FI', 'DK'].includes(this.countryCode)
    },
    isSwiss() {
      return ['CHDE', 'CHFR'].includes(this.countryCode)
    }
  },
  mounted() {
    if (!this.isPreview) {
      store.dispatch('setCountryCode', this.$route.params.countryCode)
    }
  }
}



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '~@/assets/styles/normalize.css';
@import '~@/assets/styles/main.css';
</style>
