<template>
  <div class="page-content">
    <div class="products-overview">
      <router-link
        v-for="item in products"
        :key="item.id"
        :to="{ path: '/' + countryCode + '/' + catalogType + '/barbecues/' + categoryName + '/' + item.product.importId }"
        class="products-overview-single"
      >
        <img
          :src="'https://onlinedatabasetool.com/template-assets/img/barbecues/' + item.product.barbecue_code['value'] + '.jpg'"
        >
        <div class="product-single-text">
          {{ item.product.product_title_line_1['value'] }} - {{ item.product.product_title_line_2['value'] }} <span
            v-if="item.product.product_title_line_3['value']"
          >- {{ item.product.product_title_line_3['value'] }}</span>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProductOverview',
  props: {
    msg: String,
    products: Array,
  },
  data() {
    return {
      categoryClass: 'cat-' + this.$route.params.categoryName,
      countryCode: this.$route.params.countryCode,
      catalogType: this.$route.params.catalogType,
      categoryName: this.$route.params.categoryName,
      catalogArray: [],
      publicationId: 0
    }
  },
  created() {
    this.$parent.showHeader = true,
      this.$parent.showDownloadButton = true
  }

}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
