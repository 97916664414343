<template>
  <div v-if="!isLoading" class="products-overview">
    <div v-for="item in accessories" :key="item.id">
      <div v-if="isSubheading(item)" class="subheading" :class="subheadingClass">
        <div><strong>{{ item.subheading.title }}</strong></div>
        {{ item.subheading.content }}
      </div>
      <router-link v-if="isProduct(item)" :to="productLink(item)" class="products-overview-single"
        :key="item.product.importId">
        <img :src="productImage(item)">
        <div class="product-single-text">
          {{ item.product.product_title_line_1_accessories?.value }}
          <span v-if="item.product.product_title_line_2_accessories">
            - {{ item.product.product_title_line_2_accessories?.value }}
          </span>
        </div>
      </router-link>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex';

export default {
  name: 'AccessoriesOverview',
  props: {
    accessories: {
      type: Array,
      required: true
    },
  },
  computed: {
    ...mapState(['isLoading']),
    subheadingClass() {
      return 'subheading-cat-' + this.$route.params.categoryName;
    },
    isSubheading() {
      return (item) => item.type === 'SUBHEADING';
    },
    isProduct() {
      return (item) => item.type === 'PRODUCT';
    },
    productLink() {
      return (item) => ({
        path: `/${this.$route.params.countryCode}/${this.$route.params.catalogType}/${encodeURIComponent(this.$route.params.categoryName)}/${item.product.importId}`
      });
    },
    productImage() {
      return (item) => `https://onlinedatabasetool.com/template-assets/img/accessoires/${item.product.article_number_acc_sp_1.value}.jpg`;
    }
  },
  created() {
    this.$parent.showHeader = true;
    this.$parent.showDownloadButton = true;
  }
}
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
