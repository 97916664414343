<template>
  <section class="content">
    <div class="page-content page-content-noheader">
      <div v-if="!isLoading">
        <div class="cat-overview">
          <h1>
            {{ pageTitle }}
          </h1>
          <!-- For regular BBQ categories -->
          <div v-for="item in BBQCategories" :key="item.id" class="cat-overview-links">
            <router-link :to="{ path: '/' + countryCode + '/' + catalogType + '/barbecues/' + item.code }"
              :class="item.code">
              {{ item.label }}
            </router-link>
          </div>
        </div>
        <!-- End regular BBQ categories -->

        <!-- For non-BBQ categories  / Accessories -->
        <div class="cat-overview">
          <h1>{{ accessoryTitle }}</h1>

          <div v-for="item in nonBBQCategories" :key="item.id" class="cat-overview-links">
            <router-link :to="{ path: '/' + countryCode + '/' + catalogType + '/' + item.code }" :class="item.code">
              {{ item.label }}
            </router-link>
          </div>
        </div>
        <!-- End non-BBQ categories -->


        <!-- For Spare Parts -->
        <div class="cat-overview">
          <h1>{{ sparePartsTitle }}</h1>

          <div v-for="item in sparePartsCategories" :key="item.id" class="cat-overview-links">
            <router-link :to="{ path: '/' + countryCode + '/' + catalogType + '/' + item.code }" :class="item.code">
              {{ item.label }}
            </router-link>
          </div>

          <!-- End non-BBQ categories -->

          <!-- Claim Policy block for nordics only-->
          <div v-if="isNordic">
            <router-link :to="{ path: '/pdf/claim-policy/' + countryCode + '.pdf' }" target="_blank"
              class="claim-policy">
              {{ claimPolicyCountries[countryCode] }}
            </router-link>
          </div>
          <!-- End claim policy block -->
        </div>

        <!-- Legendatable-->
        <div v-if="labels.length" class="legenda">
          <table>
            <tr v-if="labelcaption('pure_online_assortment_icon')">
              <td>
                <img src="//onlinedatabasetool.com/template-assets/img/PURE_ONLINE_ASSORTMENT_ICON.png"
                  alt="Pure Online assortment" style="max-width:25px;">
              </td>
              <td style="font-size:10px;">
                {{ labelcaption('pure_online_assortment_icon') }}
              </td>
            </tr>
            <tr v-if="labelcaption('partner_icon')">
              <td width="35">
                <img src="//onlinedatabasetool.com/template-assets/img/icon-partner.png" alt="Partner"
                  style="max-width:25px;">
              </td>
              <td style="font-size:10px;">
                {{ labelcaption('partner_icon') }}
              </td>
            </tr>
            <tr v-if="labelcaption('premium_icon')">
              <td>
                <img src="//onlinedatabasetool.com/template-assets/img/icon-premium.png" alt="Premium"
                  style="max-width:25px;">
              </td>
              <td style="font-size:10px;">
                {{ labelcaption('premium_icon') }}
              </td>
            </tr>
            <tr v-if="labelcaption('weber_world_icon')">
              <td>
                <img src="//onlinedatabasetool.com/template-assets/img/WEBER_WORLD_ICON.png"
                  alt="Weber World assortment" style="max-width:25px;">
              </td>
              <td style="font-size:10px;">
                {{ labelcaption('weber_world_icon') }}
              </td>
            </tr>
            <tr v-if="labelcaption('weber_own_channels_icon')">
              <td width="35">
                <img src="//onlinedatabasetool.com/template-assets/img/WEBER_OWN_CHANNELS_ICON.png"
                  alt="Weber Own Channels" style="max-width:25px;">
              </td>
              <td style="font-size:10px;">
                {{ labelcaption('weber_own_channels_icon') }}
              </td>
            </tr>
            <tr v-if="labelcaption('urban_electro_icon')">
              <td>
                <img src="//onlinedatabasetool.com/template-assets/img/urban_electro_icon_round.png"
                  alt="Urban Electro assortment" style="max-width:25px;">
              </td>
              <td style="font-size:10px;">
                {{ labelcaption('urban_electro_icon') }}
              </td>
            </tr>
            <tr v-if="labelcaption('non_sds_icon')">
              <td>
                <img src="//onlinedatabasetool.com/template-assets/img/NON_SDS_ICON.png" alt="Non SDS assortment"
                  style="max-width:25px;">
              </td>
              <td style="font-size:10px;">
                {{ labelcaption('non_sds_icon') }}
              </td>
            </tr>
            <tr v-if="labelcaption('packaging_size_GS1_compliant')">
              <td>
                <img src="//onlinedatabasetool.com/template-assets/img/iconen/GS1.png" alt="GS1"
                  style="max-width:25px;">
              </td>
              <td style="font-size:10px;">
                {{ labelcaption('packaging_size_GS1_compliant') }}
              </td>
            </tr>
            <tr v-if="labelcaption('bic_icon')">
              <td>
                <img src="//onlinedatabasetool.com/template-assets/img/icon-BIC.png" alt="Best in Class"
                  style="max-width:25px;">
              </td>
              <td style="font-size:10px;">
                {{ labelcaption('bic_icon') }}
              </td>
            </tr>
            <tr v-if="labelcaption('fsc_icon')">
              <td>
                <img src="//onlinedatabasetool.com/template-assets/img/FSC_icon_2023.jpeg" alt="FSC"
                  style="max-width:34px;">
              </td>
              <td style="font-size:10px;">
                {{ labelcaption('fsc_icon') }}
              </td>
            </tr>
            <tr v-if="labelcaption('stock_icon')">
              <td>
                <img src="//onlinedatabasetool.com/template-assets/img/icon-stock.png" alt="Until stock lasts"
                  style="max-width:25px;">
              </td>
              <td style="font-size:10px;">
                {{ labelcaption('stock_icon') }}
              </td>
            </tr>
          </table>
          <div v-if="labelcaption('ddc_legal_text_1') || labelcaption('ddc_legal_text_2')" class="disclaimer">
            <p v-if="labelcaption('ddc_legal_text_1')">
              {{ labelcaption('ddc_legal_text_1') }}
            </p>
            <p v-if="labelcaption('ddc_legal_text_2')">
              {{ labelcaption('ddc_legal_text_2') }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'


export default {
  name: 'CategoriesOverview',
  computed: {
    ...mapState(['countryId', 'categories', 'labels', 'countryCode', 'isLoading']),
    catalogType() {
      return this.$route.params.catalogType
    },
    claimPolicyCountries() {
      return {
        'NO': "Reklamasjonspolicy",
        'SE': "Reklamationspolicy",
        'DK': "Reklamationspolitik",
        'FI': "Reklamaatioperiaatteet"
      }
    },
    pageTitle() {
      if (this.countryCode === "DEAT") {
        return "Grills"
      } else if (this.countryCode === "FI") {
        return "Grillit"
      } else {
        return "Barbecues"
      }
    },
    isNordic() {
      return ['NO', 'SE', 'FI', 'DK'].includes(this.countryCode)
    },
    accessoryTitle() {
      return this.categories.find(category => category.id === 5)?.label || null
    },
    sparePartsTitle() {
      return this.categories.find(category => category.id === 6)?.label || null
    },
    BBQCategories() {
      return this.categories.filter(category => category.isBarbecueCategory === true);
    },
    nonBBQCategories() {
      // return this.categories.filter(category => category.isBarbecueCategory === false);
      return this.categories.filter(category => category.id === 5);
    },
    sparePartsCategories() {
      return this.categories.filter(category => category.id === 6);
    }
  },

  watch: {
    '$route'(to, from) {
      if (to.params.countryCode !== from.params.countryCode || to.params.catalogType !== from.params.catalogType) {
        this.fetchData(to.params.countryCode, to.params.catalogType);
      }
    }
  },

  created() {
    this.$parent.showHeader = true;
    this.$parent.showDownloadButton = false;
  },

  mounted() {
    this.fetchData(this.$route.params.countryCode, this.$route.params.catalogType);
  },

  methods: {
    fetchData(countryCode, catalogType) {
      this.$store.dispatch('setCategories', { countryCode, catalogType });
      this.$store.dispatch('setCountryId', countryCode);
      this.$store.dispatch('setCountryCode', countryCode);
    },
    labelcaption(code) {
      const label = this.labels.find(l => l.code === code);
      return label ? label.caption : "";
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
