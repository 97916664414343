
import Protected from '@/components/Protected';
import AccessoryBaseTemplate from '@/components/accessory/AccessoryBaseTemplate';
import Catalogue from '@/components/catalog/catalogParts/Catalogue';
import Categories from '@/components/catalog/catalogParts/Categories';
import CountriesOverview from '@/components/catalog/catalogParts/Countries';
import ProductBaseTemplate from '@/components/product/ProductBaseTemplate';
import storageHelper from 'storage-helper';
import { createRouter, createWebHistory } from "vue-router";


// Define some routes
const routes = [
    {
        path: '/',
        name: 'Base',
        props: true,
        component: CountriesOverview
    },
    {
        path: '/protected/',
        name: 'Protected',
        props: true,
        component: Protected
    },
    {
        path: '/:countryCode/',
        name: 'Catalogue',
        props: true,
        component: Catalogue,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/:countryCode/:catalogType/',
        name: 'Categories',
        props: true,
        component: Categories,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/:countryCode/:catalogType/barbecues/:categoryName/',
        name: 'Products',
        props: true,
        component: ProductBaseTemplate,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/:countryCode/:catalogType/:categoryName/',
        name: 'Accessories',
        props: true,
        component: AccessoryBaseTemplate,
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/:countryCode/:catalogType/barbecues/:categoryName/:importId/',
        name: 'Product',
        props: true,
        component: ProductBaseTemplate,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/:countryCode/:catalogType/:categoryName/:importId/',
        name: 'Accessory',
        props: true,
        component: AccessoryBaseTemplate,
        meta: {
            requiresAuth: true
        }
    },
]


// 3. Create the router instance and pass the `routes` option
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        if (!storageHelper.getItem('user-password')) {
            next({
                path: '/protected',
                query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    } else next()
})


router.beforeEach(() => {
    router.loading = true
})

export default router
